import React from "react";
import ctabg1 from "../assets/images/patter-1-1.jpg";
import { Link } from "gatsby";
import team1 from "../assets/images/anil_patel.png";
import team2 from "../assets/images/dhaval_patel.png";
import team3 from "../assets/images/JIGAR_DESAI.png";
import team4 from "../assets/images/PRATIK_PATEL.png";
import team5 from "../assets/images/RAJESHRE_SHAH.png";
import team6 from "../assets/images/RONAK_MISTRY.png";
import team7 from "../assets/images/SNEHAL_CHHA_1.png";
const TeamSASAdmin = () => {
    return (
        <div>
            <section
                className="cta-one cta-one__home-one mt-2 mb-2"
                style={{ backgroundImage: `url(${ctabg1})` }}
            >
                <div className="container">
                    <h2 className="cta-one__titlese team-details__text">
                        We believe that a committed and knowledgeable faculty is the backbone of successful delivery of
                        education to children. Each of our school is led by an extremely talented team of teachers and talent
                        is not the only attribute they possess. Each one of our teachers have an innate passion, not just to
                        teach but also to learn.<br /><br />
                        Our teachers are experts in their fields- in knowledge, in flexibility, in commitment and in
                        motivational skills and they realise this passion by engaging with the young people. Being with
                        them, living their lives, understanding them, before making them understand the lessons of life. The
                        school also believes in developing its teachers and encouraging them to enhance their skills.
                    </h2>

                </div>
            </section>

            <section className="team-one team-page">
                <div className="container">
                    <div className="row">

                        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                            <div className="team-one__single">
                                <div className="team-one__image">
                                    <img src={team1} alt="" />
                                </div>
                                <div className="team-one__content">
                                    <h2 className="team-one__name">
                                        <Link to="#">ANIL PATEL</Link>
                                    </h2>
                                    <p className="team-one__designation">Store & Purchase Manager</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                            <div className="team-one__single">
                                <div className="team-one__image">
                                    <img src={team4} alt="" />
                                </div>
                                <div className="team-one__content">
                                    <h2 className="team-one__name">
                                        <Link to="#">PRATIK PATEL</Link>
                                    </h2>
                                    <p className="team-one__designation">Account Assistant</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                            <div className="team-one__single">
                                <div className="team-one__image">
                                    <img src={team2} alt="" />
                                </div>
                                <div className="team-one__content">
                                    <h2 className="team-one__name">
                                        <Link to="#">DHAVAL PATEL</Link>
                                    </h2>
                                    <p className="team-one__designation">Transport & Housekeeping In-charge</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    );
};

export default TeamSASAdmin;
