import React from 'react';
import Layout from "../components/Layout";
import NavOne from "../components/NavOne";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import TeamSASAdmin from '../components/TeamSASAdmin';
import Topbar from '../components/Topbar';
const TeamSASAdminPage = () => {
    return (
        <Layout pageTitle="Shanti Asiatic School | Team SAS">
            <Topbar />
            <NavOne />
            <PageHeader title="Admin Team" />
            <TeamSASAdmin />
            <Footer />
        </Layout>
    );
};

export default TeamSASAdminPage;
